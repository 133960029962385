import {Skeleton, Text, type TextProps} from '@mantine/core';
import type {ConfigType} from 'dayjs';
import dayjs from 'dayjs';
import type {ReactNode} from 'react';

import {ClientOnly} from '~/components/ClientOnly';

const defaultFallback = <Skeleton w={120} h={12} radius="sm" />;

interface DateTimeProps extends TextProps {
  date?: ConfigType;
  fallback?: ReactNode;
  invalidFallback?: string;
  format?: string;
  relative?: boolean;
}

export function DateTime({
  date: dateProp,
  fallback = defaultFallback,
  invalidFallback = 'N/A',
  format = 'LLL',
  relative,
  ...others
}: DateTimeProps) {
  return (
    <ClientOnly fallback={fallback}>
      {() => {
        const date = dayjs(dateProp);
        return (
          <Text {...others}>
            {date.isValid() ? (relative ? dayjs().to(date) : date.format(format)) : invalidFallback}
          </Text>
        );
      }}
    </ClientOnly>
  );
}
